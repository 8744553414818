import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrSamplaskiLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Samplaski"
    institution="USC"
    referralCode="DRSAMPLASKI"
    physicianURL="https://keck.usc.edu/faculty-search/mary-samplaski/"
  />
)

export default DrSamplaskiLandingPage
